import React from 'react'
import { SimCitySpinner } from './simCitySpinner'

class IframeLoading extends React.Component {
  state = { loading: true }
  constructor(props) {
    super(props)
    this.iframe = React.createRef()
  }
  componentDidMount() {
    this.iframe.current.addEventListener('load', () =>
      this.setState.bind(this)({ loading: false })
    )
  }

  render() {
    let iframeCss = {}
    iframeCss['display'] = this.state.loading ? 'none' : undefined
    return (
      <React.Fragment>
        {this.state.loading ? <SimCitySpinner /> : null}
        <iframe ref={this.iframe} {...this.props} css={iframeCss} />
      </React.Fragment>
    )
  }
}

export { IframeLoading }
