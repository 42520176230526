export const Colors = {
    primaryColor  : '#32BF9A' ,
    secondaryColor : '4D4D4D' ,
    thirdColor  : '#ED6A5A' ,
    backgroundColor : '#e0e0e0',
    backSecondaryColor: '#4e66ea',
    textPrimaryColor : '#4D4D4D', 
    textSecondaryColor : '#FFFFFF',
    logoOrange: '#FAB400'
}


