import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import { IframeLoading } from '../components/common/IframeLoading'

const iframeWrapperCss = {
  height: '85vh',
}
const iframeCss = {
  width: '100%',
  height: '100%',
}
const Individual = props => (
  <Layout>
    <Helmet>
      <title>Free text annotation tool</title>
      <meta
        name="description"
        content="A web based annotation tool for named entity recognition. Work offline, your data never leaves your computer"
      />
    </Helmet>
    <h1>LightTag's Free Annotation Tool for individuals</h1>
    <h2>
      Copy and paste your data (or upload a JSON), and annotate away. <br />
      Data never leaves your computer
    </h2>
    <div css={iframeWrapperCss}>
      <IframeLoading
        css={iframeCss}
        allowfullscreen
        src={process.env.INDIVIDUAL_MODE_URL}
      />
    </div>
  </Layout>
)
export default Individual
