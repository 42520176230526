import React from 'react'
import './spinner.css'

export const Spinner = props => (
  <div className="loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default Spinner
